
body {
  margin: 0;
  height: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.container {
  margin: 0 auto;
  max-width: 1100px;
  padding: 0 40px;
}

@media screen and (max-width: 768px) {
  .container {
      padding: 0 25px;
  }
}

h1 {
  margin: 0;
  letter-spacing: 3px;
  color: #434343;
}

h2 {
  color: #434343;
}

a {
  text-decoration: none;
  color: inherit;
}

p, li {
  color: #585858;
  font-size: 1rem;
  line-height: 2rem;
}


.site-btn {
  border: none;
  padding: 0.7em 1.50em;
  border-radius: 4px;
  font-size: 1.1rem;
  font-weight: 700;
  font-family: 'Source Sans Pro', sans-serif;
  background-color: #434343;
  color: white;
  z-index: 999;
  cursor: pointer;
  white-space: nowrap;
}