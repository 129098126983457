.proj-slider-container {
    display: flex;
    overflow-x: auto;
    box-sizing: border-box;
}

.proj-slider-container:after {
    content: "";
    display: block;
    height: 50px;
    width: 100%;
  }

.proj-slider-container::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
}

.proj-slider-controls {
    padding: 0 100px;
    position: relative;
}

.scroll-area {
    height: 120px;
    width: 60px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background-color: white;
}

.scroll-area img {
    width: 10px;
    margin-bottom: 20px;
}

#scrollLeft {
    /* background-color: red; */
    display: none;
    left: 0;
}

#scrollRight {
    /* background-color: blue; */
    right: 0;
}

@media screen and (min-width: 1300px) {
    .scroll-area {
        width: 5.3vw;
    }
}

@media screen and (max-width: 700px) {
    #scrollLeft {
        left: -60px;
    }
    
    #scrollRight {
        right: -60px;
    }
    
}

@media screen and (max-width: 572px) {

    #scrollLeft {
        left: -25px;
    }
    
    #scrollRight {
        right: -25px;
    }
    
}