
.project-grid {
    max-width: 850px;
    height: max-content; 
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    flex-wrap: wrap;
    /* background-color: red; */
}


.projicon-container-small {
    width: 100px;
    height: 100px;
    margin-right: 20px;
}

.projicon-container {
    width: 180px; 
    height: 180px;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    transition: 0.1s linear;
    background-size: cover;
    margin-bottom: 40px;
}

.projicon-title {
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: -30px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    font-size: 1rem;
    padding-left: 10px;
    transition: 0.1s linear;
}

.projicon-container:hover .projicon-title{
    bottom: 0;
}

.icon {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 610px) {
    .projicon-container {
        width: 150px;
        height: 150px;
    }
    .projicon-title {
        display: none;
    }

}

@media screen and (max-width: 515px) {
    .projicon-container {
        width: 100px;
        height: 100px;
    }
    
}

@media screen and (max-width: 370px) {
    .projicon-container {
        width: 80px;
        height: 80px;
    }
    
}
