.dev-section {
    height: max-content;
    position: relative;
    margin-bottom: 100px;
    /* background-color: gray; */
}

.screen-wrapper {
    position: absolute;
    right: -40px; 
    top: 0;
    width: 46vw;
    height: 100%;
    /* background-color: red; */
}

.screen {
    width: 824px;
    height: 530px;
    background: white;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;    
    position: sticky;
    top: 22vh;
    /* transform: translate(0%, -50%); */
    bottom: 0;
    box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
    0 2px 4px rgba(0,0,0,0.07), 
    0 4px 8px rgba(0,0,0,0.07), 
    0 8px 16px rgba(0,0,0,0.07),
    0 16px 32px rgba(0,0,0,0.07), 
    0 32px 64px rgba(0,0,0,0.07);
}

.screen-content {
    width: 97%;
    height: 95%;
    border-radius: 10px;
    overflow: hidden;
}

.screen-content img {
    width: 100%;
}

.full-width {
    margin-bottom: 50px;
}

.full-width:first-of-type {
    margin-top: 50px;
}

.tech-container {
    height: 530px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 200px;
    position: relative;
    /* background-color: lightblue; */
}

.tech-container p {
    width: 50%;
}

.tech-trigger {
    width: 100%;
    height: 1px;
    position: absolute;
    margin: auto auto;
    top: 0;
    bottom: 0;
    /* background-color: green; */
}

@media screen and (max-width: 992px) {
    .tech-trigger {
        display: none;
    }
    .screen-wrapper {
        display: none;
    }
    .slant-container {
        margin-bottom: 200px;
    }
    .tech-container:first-of-type {
        text-align: center;
    }
    .tech-container {
        height: max-content;
        margin-bottom: 100px;
        /* background-color: lightblue; */
        
    }
    .tech-container p {
        width: 100%;
    }
}
